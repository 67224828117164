<template>

  <div class="pt-2">

    <div>

      <b-row align-v="center">

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <label>Opisz jaką grafikę ma wygenerować AI:</label>

              <b-input-group>

                <b-form-input v-model="query" size="lg" ref="query" rows=10 placeholder="np. różowy koń na polanie" v-on:keyup.enter="generateFinish()"></b-form-input>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>

      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Generuj grafikę
          </b-button>

        </b-col>

      </b-row>



      <div class="mt-2" >

        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje grafikę <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Proszę czekać...</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0 text-center" style="font-size: 1em;">

            <img ref="outputImg" style="max-width:50%;height:auto;" src="" />

          </blockquote>

        </b-card>

      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: 'AppGraphicNAi',
  components: {
  },
  data() {
    return {
      isFinish: false,
      result: "",
      generatedImg: false,
      selectedText: '',
      query: "",
      finishContent: '',
      selectedSchema: null
    }
  },
  methods: {

    generateFinish: function() {

      if(!this.query) return false;

      this.$refs.generatePost.disabled = true

      this.isFinish = true

      this.finishContent = ""
      this.result = ""

      var query = "";


      query = "Przetłumacz na angielski: '" + this.query + "'";

      var doneText = "";

      this.axios.post(window.API_URL + "text/generateFromAI/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        doneText = data.result;

        this.finishContent = doneText;

        this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

        this.$refs.outputImg.src = ""

        var prompt = this.result;

        this.axios.post(window.API_URL + "images/generateOnce", { 'prompt': prompt }, { withCredentials: true, timeout: 320000 }).then((response2) => {

          let data = response2.data

          this.finishContent = true;
          //"data:image/jpeg;charset=utf-8;base64," + data.result[0];
          //this.$refs.outputImg.src = "data:image/png;charset=utf-8;base64," + data;
          this.$refs.outputImg.src = data;

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana grafika"


        }).catch(function() {

          this.$refs.generatePost.disabled = false

        });


      }).catch(function() {

        this.$refs.generatePost.disabled = false

      })



    }

  },
  computed: {

  },
  created() {

    this.$watch('finishContent', (value) => {

      var lines = value.split(/\r|\r\n|\n/);

      this.finishLinesCount = lines.length;

    })

  }

}
</script>

<style>

.pointer { cursor: pointer; }
.bold { font-weight: 600 !important; }
code { color: #4e82f7 !important; }
</style>



